import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export const numberToString = (x) => {
  try {
    if (x === undefined) {
      return '0';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch (err) {
    return '0';
  }
};

export const stringToNumber = (str) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseInt(str.replace(/,/g, ''), 10))) {
      return 0;
    }
    return parseInt(str.replace(/,/g, ''), 10).toString();
  } catch (err) {
    return 0;
  }
};
