import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import DefaultTable from '../components/tables/DefaultTable';
import { generateFilterField, generateHeadCell } from '../utils/inputGenerator';
import { InputType } from '../constants/InputType';
import { ApiPath } from '../constants/ApiPath';

function Customer() {
  const filters = [
    generateFilterField('start_date', 'Start Date', InputType.DATE, null, null, null),
    generateFilterField('end_date', 'End Date', InputType.DATE, null, null, null),
    generateFilterField('name', 'Name', InputType.TEXT, null, null, ''),
    generateFilterField('sales', 'Sales', InputType.TEXT, null, null, ''),
    generateFilterField('company', 'Company', InputType.TEXT, null, null, ''),
    generateFilterField('phone', 'Phone', InputType.TEXT, null, null, ''),
    generateFilterField('address', 'Address', InputType.TEXT, null, null, ''),
    generateFilterField('instagram', 'Instagram', InputType.TEXT, null, null, ''),
    generateFilterField('email', 'Email', InputType.TEXT, null, null, ''),
    generateFilterField('web', 'Web', InputType.TEXT, null, null, ''),
    generateFilterField(
      'category',
      'Category',
      InputType.SELECT,
      null,
      {
        url: ApiPath.GET_CATEGORIES,
        textKey: 'name',
        valueKey: 'id'
      },
      ''
    ),
    generateFilterField(
      'classification',
      'Classification',
      InputType.SELECT,
      null,
      {
        url: ApiPath.GET_CLASSIFICATIONS,
        textKey: 'name',
        valueKey: 'id'
      },
      ''
    )
  ];

  const headCells = [
    generateHeadCell('name', 'Name', InputType.TEXT, true),
    generateHeadCell('sales', 'Sales', InputType.TEXT, true),
    generateHeadCell('company_name', 'Company', InputType.TEXT, true),
    generateHeadCell('phone_number', 'Phone', InputType.TEXT, true),
    generateHeadCell('address', 'Address', InputType.TEXT, true),
    generateHeadCell('instagram', 'Instagram', InputType.TEXT, true),
    generateHeadCell('email', 'Email', InputType.TEXT, true),
    generateHeadCell('web', 'Web', InputType.TEXT, true),
    generateHeadCell('category', 'Category', InputType.TEXT, true),
    generateHeadCell('classification', 'Classification', InputType.TEXT, true),
    generateHeadCell('visitation_count', 'Visitation Count', InputType.NUMBER, true)
  ];

  return (
    <Page title="Customer">
      <Container maxWidth="xl">
        <DefaultTable
          hasDetail
          getUrl={ApiPath.GET_CUSTOMERS_LIST}
          headCells={headCells}
          filters={filters}
          title="Customer"
        />
      </Container>
    </Page>
  );
}

export default Customer;
