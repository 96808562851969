import MySwal from '../swal';

const Axios = require('axios');

const client = Axios.create();

const cookies = require('react-cookies');

client.interceptors.request.use((config) => {
  const token = cookies.load('ACCESS_TOKEN');

  if (token !== undefined) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    try {
      if (error.response.status === 401) {
        if (window.location.pathname !== '/login') {
          MySwal.information('', 'Session anda habis, silahkan login kembali').then(() => {
            window.location.href = '/login';
          });
        } else {
          MySwal.information('', 'Username atau password salah').then(() => {});
        }
      } else if (error.response !== undefined && error.response.data.error !== undefined) {
        MySwal.error('', error.response.data.error);
      } else {
        MySwal.error('', `${error.response.status} : ${error.response.statusText}`);
      }
    } catch (exception) {
      MySwal.error('Unspecified error.');
    }

    return Promise.reject(error);
  }
);

export default class ApiClient {
  static Download = (url, pathVariables, params) =>
    client.get(ApiClient.buildUrl(url, pathVariables), {
      params,
      responseType: 'blob'
    });

  static Get = (url, pathVariables, params) =>
    client?.get(ApiClient.buildUrl(url, pathVariables), {
      params
    });

  static Post = (url, pathVariables, body) =>
    client?.post(ApiClient.buildUrl(url, pathVariables), body);

  static PostFormData = (url, pathVariables, body) => {
    const data = new FormData();
    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });
    return client?.post(ApiClient.buildUrl(url, pathVariables), data);
  };

  static Put = (url, pathVariables, body) =>
    client?.put(ApiClient.buildUrl(url, pathVariables), body);

  static Delete = (url, pathVariables) => client?.delete(ApiClient.buildUrl(url, pathVariables));

  static buildUrl = (url, pathVariables) => {
    pathVariables.forEach((variable) => {
      url = url.replace('{}', variable);
    });
    return url;
  };
}
