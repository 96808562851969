export const InputType = {
  TEXT: 'text',
  CURRENCY: 'currency',
  NUMBER: 'number',
  DECIMAL: 'decimal',
  EMAIL: 'email',
  MULTILINE: 'multiline',
  NUMERIC: 'numeric',
  PASSWORD: 'password',
  SELECT: 'select',
  DATETIME: 'datetime',
  DATE: 'date',
  AUTOCOMPLETE: 'autocomplete',
  MULTIPLE_AUTOCOMPLETE: 'multiple-autocomplete'
};
