import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Input from '../Input';
import { TableContext } from '../../context/TableContext';

DefaultTableToolbar.propTypes = {
  creatable: PropTypes.bool,
  filters: PropTypes.array,
  createFields: PropTypes.array
};

export default function DefaultTableToolbar({ filters }) {
  const [request, setRequest] = useState({});

  const context = useContext(TableContext);

  useEffect(() => {
    const temp = {};
    filters.forEach((field) => {
      temp[field.name] = field.defaultValue !== undefined ? field.defaultValue : '';
    });
    setRequest(temp);
  }, []);

  const handleSubmit = () => {
    context.setFilter(request);
  };

  const handleReset = () => {
    const temp = {};
    filters.forEach((field) => {
      temp[field.name] = field.defaultValue === undefined ? '' : field.defaultValue;
    });
    setRequest(temp);
    context.setFilter(temp);
  };

  const handleExport = () => {
    context.exportVisitation();
  };

  return (
    <Grid container spacing={2}>
      {filters.length > 0 && (
        <>
          <Grid item xs={12} container spacing={1}>
            {filters.map((filter) => (
              <Grid item key={filter.name}>
                <Input
                  {...filter}
                  name={filter.name}
                  multiline={false}
                  type={filter.type}
                  label={filter.label}
                  value={request[filter.name]}
                  onChange={(name, value) => {
                    setRequest({
                      ...request,
                      [name]: value
                    });
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <Button onClick={handleReset}>Reset</Button>
          </Grid>
          <Grid item>
            <Button onClick={handleSubmit}>Terapkan</Button>
          </Grid>
          {window.location.pathname === '/visitations' && (
            <Grid item>
              <Button onClick={handleExport}>Export</Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
