import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbar from '../Scrollbar';
import DefaultTableToolbar from './DefaultTableToolbar';
import { TableContext } from '../../context/TableContext';
import Iconify from '../Iconify';
import { fNumber } from '../../utils/formatNumber';
import DefaultCreateDialog from './DefaultCreateDialog';
import DefaultTableMoreMenu from './DefaultTableMoreMenu';
import DefaultUpdateDialog from './DefaultUpdateDialog';
import MySwal from '../../utils/swal';
import ApiClient from '../../utils/api/ApiClient';
import { ProductRepository } from '../../repositories/ProductRepository';

DefaultTable.propTypes = {
  creatable: PropTypes.bool,
  filters: PropTypes.any,
  createFields: PropTypes.array,
  updateFields: PropTypes.array,
  headCells: PropTypes.array,
  check: PropTypes.any,
  updatable: PropTypes.bool,
  deletable: PropTypes.bool,
  title: PropTypes.string,
  createUrl: PropTypes.string,
  updateUrl: PropTypes.string,
  deleteUrl: PropTypes.string,
  getUrl: PropTypes.string,
  hasProducts: PropTypes.bool,
  hasDetail: PropTypes.bool
};

function DefaultTable({
  creatable,
  hasProducts,
  updateFields,
  filters,
  createFields,
  headCells,
  check,
  title,
  updatable,
  deletable,
  getUrl,
  createUrl,
  updateUrl,
  deleteUrl,
  hasDetail
}) {
  const {
    checked,
    data,
    setChecked,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    totalData,
    size,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getData,
    setGetUrl,
    setPage,
    setSize
  } = useContext(TableContext);

  const [isCreating, setIsCreating] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setOrder('');
    setOrderBy('');
    setPage(0);
    setSize(10);
    setGetUrl(getUrl);
  }, [getUrl]);

  useEffect(() => {
    if (hasProducts) {
      ProductRepository.getProductSelectList().then((res) => {
        setProducts(
          res.data.contents.map((product) => ({
            value: product.id,
            text: product.label
          }))
        );
      });
    }
  }, [hasProducts]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheck = (id) => {
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
    } else {
      checked.push(id);
    }
    setChecked(checked);
  };

  const renderTableHead = () => (
    <TableHead>
      {check !== undefined && checked.length > 0 && (
        <TableRow>
          <TableCell colSpan={check?.checkable ? headCells.length + 1 : headCells.length}>
            <Box justifyContent="space-between" display="flex" width="100%" alignItems="center">
              <span>{checked.length} items checked</span>
            </Box>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {check?.checkable && (
          <TableCell>
            <Checkbox
              checked={data.map((res) => res[check?.key]).every((val) => checked.includes(val))}
              onChange={() => {
                if (data.map((res) => res[check?.key]).every((val) => checked.includes(val))) {
                  data.contents.forEach((res) => {
                    checked.splice(checked.indexOf(res[check?.key]), 1);
                  });
                } else {
                  data.contents.forEach((res) => {
                    if (!checked.includes(res[check?.key])) {
                      checked.push(res[check?.key]);
                    }
                  });
                }
                setChecked(checked);
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: 'bold' }}
            key={headCell.name}
            sortDirection={orderBy === headCell.name ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.name}
                direction={orderBy === headCell.name ? order : 'asc'}
                onClick={() => {
                  if (headCell.sortable) {
                    handleRequestSort(headCell.name);
                  }
                }}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        {(updatable || deletable || hasDetail) && <TableCell />}
      </TableRow>
    </TableHead>
  );

  const handleDelete = (id) => {
    MySwal.yesNo('Hapus Data', `Apakah anda ingin menghapus ${title}?`).then((res) => {
      if (res.isConfirmed) {
        ApiClient.Delete(deleteUrl, [id.toString()]).then((res) => {
          MySwal.success('Sukses', `Sukses menghapus ${title}`);
          getData();
        });
      }
    });
  };

  return (
    <>
      {creatable && (
        <DefaultCreateDialog
          products={products}
          hasProducts={hasProducts}
          createUrl={createUrl}
          title={title}
          onClose={() => {
            setIsCreating(false);
          }}
          open={isCreating}
          fields={createFields}
        />
      )}
      {updatable && (
        <DefaultUpdateDialog
          products={products}
          hasProducts={hasProducts}
          fields={updateFields}
          open={selectedData !== null && selectedDataId !== null}
          onClose={() => {
            setSelectedData(null);
            setSelectedDataId(null);
          }}
          title={title}
          id={selectedDataId}
          data={selectedData}
          updateUrl={updateUrl}
        />
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        {creatable && (
          <Button
            variant="contained"
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setIsCreating(true);
            }}
          >
            Add {title}
          </Button>
        )}
      </Stack>
      <Paper>
        <DefaultTableToolbar creatable={creatable} filters={filters} createFields={createFields} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {renderTableHead()}
              <TableBody>
                {data.map((row) => {
                  const isItemSelected =
                    check !== undefined && checked.indexOf(row[check.key]) !== -1;

                  const cells = [];
                  headCells.forEach((element) => {
                    let value = '-';
                    if (row[element.name] !== null && row[element.name] !== undefined) {
                      switch (element.type) {
                        case 'image':
                          value = (
                            <img
                              alt="data"
                              src={row[element.name]}
                              style={{
                                width: '300px',
                                height: '250px',
                                objectFit: 'cover'
                              }}
                            />
                          );
                          break;
                        case 'number':
                          value = fNumber(row[element.name]);
                          break;
                        case 'date':
                          value = moment(new Date(row[element.name])).format('LL');
                          break;
                        default:
                          if (typeof row[element.name] === 'string') {
                            value = row[element.name].split('\n').map((e) => <p>{e}</p>);
                          } else {
                            value = row[element.name];
                          }
                      }
                    }
                    cells.push(<TableCell align="left">{value}</TableCell>);
                  });

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      {check !== undefined && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={() => handleCheck(row[check.key])}
                          />
                        </TableCell>
                      )}
                      {cells.map((c) => c)}
                      {(deletable || updatable || hasDetail) && (
                        <TableCell>
                          <DefaultTableMoreMenu
                            id={row.id}
                            hasDetail={hasDetail}
                            updatable={updatable}
                            deletable={deletable}
                            onUpdateMenuClick={() => {
                              setSelectedDataId(row.id);
                              setSelectedData(row);
                            }}
                            onDeleteMenuClick={() => {
                              handleDelete(row.id);
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              {totalData === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          No Data
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalData}
          rowsPerPage={size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default DefaultTable;
