import Swal from 'sweetalert2';

export default class MySwal {
  static warning(title, text) {
    return Swal.fire({
      title,
      text,
      icon: 'warning'
    });
  }

  static information(title, text) {
    return Swal.fire({
      title,
      text,
      icon: 'info'
    });
  }

  static error(title, text) {
    return Swal.fire({
      title,
      text,
      icon: 'error'
    });
  }

  static success(title, text) {
    return Swal.fire({
      title,
      text,
      icon: 'success'
    });
  }

  static yesNo(title, text) {
    return Swal.fire({
      title,
      text,
      icon: 'question',
      showDenyButton: true,
      denyButtonText: 'No',
      confirmButtonText: 'Yes'
    });
  }
}
