import ApiClient from '../utils/api/ApiClient';
import { ApiPath } from '../constants/ApiPath';

export class CustomerRepository {
  static get = (request) => ApiClient.Get(ApiPath.GET_CUSTOMERS_LIST, [], request);

  static show = (id) => ApiClient.Get(ApiPath.GET_CUSTOMER_DETAIL, [id], {});

  static post = (body) => ApiClient.Post(ApiPath.CREATE_CUSTOMER, [], body);

  static put = (id, body) => ApiClient.Put(ApiPath.UPDATE_CUSTOMER, [id.toString()], body);

  static delete = (id) => ApiClient.Delete(ApiPath.UPDATE_CUSTOMER, [id.toString()]);
}
