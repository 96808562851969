// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Products',
    path: '/products',
    icon: getIcon('eva:shopping-bag-fill')
  },
  {
    title: 'customer',
    path: '/customers',
    icon: getIcon('bi:person-fill')
  },
  {
    title: 'Visitations',
    path: '/visitations',
    icon: getIcon('bxs:map')
  },
  {
    title: 'follow ups',
    path: '/follow-ups',
    icon: getIcon('fluent:clipboard-task-20-filled')
  },
  {
    title: 'survey',
    path: '/surveys',
    icon: getIcon('ri:survey-fill')
  }
];

export default sidebarConfig;
