import React, { useState } from 'react';
import { DatePicker } from '@mui/lab';
import { Icon } from '@iconify/react';
import {
  FormControl,
  InputLabel,
  Input as MaterialInput,
  InputAdornment,
  IconButton,
  TextField,
  Grid
} from '@mui/material';
import Select from './select';
import { numberToString, stringToNumber } from '../utils/formatNumber';

const moment = require('moment');

function Input(props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (key, value) => {
    props.onChange(key, value);
  };

  const handleSelectChange = (key, value) => {
    props.onChange(key, value);
  };

  const handleDateChange = (date, key) => {
    if (date !== null) {
      props.onChange(key, moment(date).format('YYYY-MM-DD'));
    } else {
      props.onChange(key, null);
    }
  };

  // const renderAutoComplete = (res) => (
  //   <Autocomplete
  //     async={res.async}
  //     name={res.name}
  //     label={res.label}
  //     type="autocomplete"
  //     value={res.value}
  //     onChange={res.onChange}
  //     defaultValue={res.defaultValue}
  //   />
  // );

  // const renderMultipleAutoComplete = (res) => (
  //   <MultipleAutocomplete
  //     async={res.async}
  //     name={res.name}
  //     label={res.label}
  //     type="autocomplete"
  //     value={res.value}
  //     onChange={res.onChange}
  //   />
  // );

  const renderDatePicker = (data) => (
    <DatePicker
      clearable
      date={null}
      style={{ width: '100%' }}
      label={data.label}
      value={data.value === null ? null : moment(data.value)}
      onChange={(date) => handleDateChange(date, data.name)}
      animateYearScrolling
      renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );

  const renderDateTimePicker = (data) => (
    <DatePicker
      clearable
      fullWidth
      label={data.label}
      value={data.value}
      onChange={(date) => handleDateChange(date, data.name)}
      animateYearScrolling
    />
  );

  const renderTextField = (data) => (
    <TextField
      fullWidth
      name={data.name}
      label={data.label}
      value={data.value}
      multiline={data.type === 'multiline'}
      onChange={(e) => {
        if (data.type === 'number') {
          handleInputChange(data.name, stringToNumber(e.currentTarget.value));
        } else {
          handleInputChange(data.name, e.currentTarget.value);
        }
      }}
      type={data.type}
    />
  );

  const renderPasswordField = (data) => (
    <FormControl fullWidth>
      <InputLabel>{data.label}</InputLabel>
      <MaterialInput
        autoComplete="false"
        type={showPassword ? 'text' : 'password'}
        value={data.value}
        onChange={(e) => {
          handleInputChange(data.name, e.currentTarget.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <Icon icon="ic:baseline-visibility" />
              ) : (
                <Icon icon="ic:baseline-visibility-off" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );

  const renderSelect = (data) => (
    <Select
      {...data}
      async={data.async}
      options={data.options}
      label={data.label}
      name={data.name}
      value={data.value === undefined || data.value === null ? '' : data.value.toString()}
      onChange={handleSelectChange}
    />
  );

  const renderInputField = (res) => {
    switch (res.type) {
      case 'email':
      case 'number':
      case 'text':
      case 'multiline':
      case 'numeric':
        return renderTextField(res);
      case 'password':
        return renderPasswordField(res);
      case 'select':
        return renderSelect(res);
      case 'datetime':
        return renderDateTimePicker(res);
      case 'date':
        return renderDatePicker(res);
      // case 'autocomplete':
      //   return renderAutoComplete(res);
      // case 'multiple-autocomplete':
      //   return renderMultipleAutoComplete(res);
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  };

  return renderInputField(props);
}

export default Input;
