import { createContext, useEffect, useState } from 'react';
import { UserRepository } from '../repositories/UserRepository';

const cookies = require('react-cookies');

const UserContext = createContext();

function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);

  const checkUser = () => {
    if (cookies.load('ACCESS_TOKEN') !== undefined && cookies.load('ACCESS_TOKEN') !== null) {
      UserRepository.getCurrentUser()
        .then((res) => {
          setUser(res.data.content);
        })
        .catch((err) => {});
    } else if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <UserContext.Provider value={{ user, checkUser }}>{children}</UserContext.Provider>;
}

export { UserContextProvider, UserContext };
