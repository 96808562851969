import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import { FollowUpRepository } from '../repositories/FollowUpRepository';

function FollowUpDetail() {
  const { id } = useParams();

  useEffect(() => {
    if (id !== null) {
      FollowUpRepository.show(id).then((res) => {
        setData(res.data.content);
      });
    }
  }, [id]);

  const [data, setData] = useState({});

  const renderField = (label, value) => (
    <TableRow>
      <TableCell style={{ fontWeight: 'bold' }}>{label}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );

  return (
    <Page title="Follow Ups">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h3">Follow Up Detail</Typography>
              <Table>
                <TableBody>
                  {renderField('Sales', data.sales)}
                  {renderField('Customer', data.customer)}
                  {renderField('Product', data.product)}
                  {renderField('Status', data.status)}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h3">Progress</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Activity</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.progress !== undefined &&
                    data.progress.map((p) => (
                      <TableRow>
                        <TableCell>{p.activity}</TableCell>
                        <TableCell>{p.date}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default FollowUpDetail;
