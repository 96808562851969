import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import DefaultTable from '../components/tables/DefaultTable';
import { generateFilterField, generateHeadCell } from '../utils/inputGenerator';
import { InputType } from '../constants/InputType';
import { ApiPath } from '../constants/ApiPath';

function Product() {
  const filters = [
    generateFilterField('notes', 'Notes', InputType.TEXT, null, null, ''),
    generateFilterField('article_id', 'Article ID', InputType.TEXT, null, null, '')
  ];

  const headCells = [
    generateHeadCell('notes', 'Notes', InputType.TEXT, true),
    generateHeadCell('article_id', 'Article ID', InputType.TEXT, true)
  ];

  return (
    <Page title="Products">
      <Container maxWidth="md">
        <DefaultTable
          getUrl={ApiPath.GET_PRODUCT_LIST}
          headCells={headCells}
          filters={filters}
          title="Products"
        />
      </Container>
    </Page>
  );
}

export default Product;
