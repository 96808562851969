import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import DefaultTable from '../components/tables/DefaultTable';
import { generateFilterField, generateHeadCell } from '../utils/inputGenerator';
import { InputType } from '../constants/InputType';
import { ApiPath } from '../constants/ApiPath';

function FollowUp() {
  const filters = [
    generateFilterField('sales', 'Sales', InputType.TEXT, null, null, ''),
    generateFilterField('customer', 'Customer', InputType.TEXT, null, null, ''),
    generateFilterField('product', 'Product', InputType.TEXT, null, null, ''),
    generateFilterField(
      'status',
      'Status',
      InputType.SELECT,
      [
        {
          value: 'aktif',
          text: 'Aktif'
        },
        {
          value: 'batal',
          text: 'Batal'
        }
      ],
      null,
      ''
    )
  ];

  const headCells = [
    generateHeadCell('start_date', 'Start Date', InputType.DATE, true),
    generateHeadCell('last_date', 'Last Date', InputType.DATE, true),
    generateHeadCell('sales', 'Sales', InputType.TEXT, true),
    generateHeadCell('customer', 'Customer', InputType.TEXT, true),
    generateHeadCell('product', 'Product', InputType.TEXT, true),
    generateHeadCell('status', 'Status', InputType.TEXT, true)
  ];

  return (
    <Page title="Follow Ups">
      <Container maxWidth="xl">
        <DefaultTable
          hasDetail
          getUrl={ApiPath.GET_FOLLOW_UP_LIST}
          headCells={headCells}
          filters={filters}
          title="Follow Ups"
        />
      </Container>
    </Page>
  );
}

export default FollowUp;
