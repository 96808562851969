import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import DefaultTable from '../components/tables/DefaultTable';
import {
  generateFilterField,
  generateHeadCell,
  generateInputFieldProps
} from '../utils/inputGenerator';
import { InputType } from '../constants/InputType';
import { ApiPath } from '../constants/ApiPath';

function Survey() {
  const filters = [
    generateFilterField('project', 'Project', InputType.TEXT, null, null, ''),
    generateFilterField('start_date', 'Start Date', InputType.DATE, null, null, null),
    generateFilterField('end_date', 'End Date', InputType.DATE, null, null, null)
  ];

  const createFields = [
    generateInputFieldProps('project', 'Project Name', InputType.TEXT, null, null, ''),
    generateInputFieldProps('start_date', 'Start Date', InputType.DATE, null, null, null),
    generateInputFieldProps('end_date', 'End Date', InputType.DATE, null, null, null)
  ];

  const updateFields = [
    generateInputFieldProps('project', 'Project Name', InputType.TEXT, null, null),
    generateInputFieldProps('start_date', 'Start Date', InputType.DATE, null, null),
    generateInputFieldProps('end_date', 'End Date', InputType.DATE, null, null)
  ];

  const headCells = [
    generateHeadCell('project', 'Project', InputType.TEXT, true),
    generateHeadCell('start_date', 'Start Date', InputType.DATE, true),
    generateHeadCell('end_date', 'End Date', InputType.DATE, true),
    generateHeadCell('positive', 'Positive', InputType.NUMBER, true),
    generateHeadCell('negative', 'Negative', InputType.NUMBER, true),
    generateHeadCell('categories_string', 'Categories', InputType.TEXT, false),
    generateHeadCell('product_lines_string', 'Product Lines', InputType.TEXT, false)
  ];

  return (
    <Page title="Survey">
      <Container maxWidth="lg">
        <DefaultTable
          createFields={createFields}
          creatable
          createUrl={ApiPath.CREATE_SURVEY}
          updatable
          updateFields={updateFields}
          updateUrl={ApiPath.UPDATE_SURVEY}
          deletable
          deleteUrl={ApiPath.DELETE_SURVEY}
          getUrl={ApiPath.GET_SURVEY_LIST}
          headCells={headCells}
          filters={filters}
          title="Survey"
        />
      </Container>
    </Page>
  );
}

export default Survey;
