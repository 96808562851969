import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import DefaultTable from '../components/tables/DefaultTable';
import { generateFilterField, generateHeadCell } from '../utils/inputGenerator';
import { InputType } from '../constants/InputType';
import { ApiPath } from '../constants/ApiPath';

function Visitation() {
  const filters = [
    generateFilterField('start_date', 'Start Date', InputType.DATE, null, null, null),
    generateFilterField('end_date', 'End Date', InputType.DATE, null, null, null),
    generateFilterField('sales', 'Sales', InputType.TEXT, null, null, ''),
    generateFilterField('customer', 'Customer', InputType.TEXT, null, null, '')
  ];

  const headCells = [
    generateHeadCell('day', 'Day', InputType.TEXT, true),
    generateHeadCell('date', 'Date', InputType.DATE, true),
    generateHeadCell('customer', 'Customer', InputType.TEXT, true),
    generateHeadCell('sales', 'Sales', InputType.TEXT, true),
    generateHeadCell('trip_time', 'Trip Time', InputType.TEXT, true),
    generateHeadCell('clock_in', 'Company', InputType.TEXT, true),
    generateHeadCell('clock_out', 'Phone', InputType.TEXT, true),
    generateHeadCell('information', 'Address', InputType.TEXT, true),
    generateHeadCell('activities', 'Activities', InputType.TEXT, false),
    generateHeadCell('products', 'Products', InputType.TEXT, false)
  ];

  return (
    <Page title="Visitation">
      <Container maxWidth="xl">
        <DefaultTable
          hasDetail
          getUrl={ApiPath.GET_VISITATION_LIST}
          headCells={headCells}
          filters={filters}
          title="Visitation"
        />
      </Container>
    </Page>
  );
}

export default Visitation;
