import { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import ApiClient from '../utils/api/ApiClient';
import { ApiPath } from '../constants/ApiPath';

const TableContext = createContext();

function TableContextProvider({ children }) {
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [getUrl, setGetUrl] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getData();
  }, [filter, page, order, orderBy, size, getUrl]);

  const getData = () => {
    if (getUrl !== '') {
      setTotalData(-1);
      setLoading(false);
      ApiClient.Get(getUrl, [], {
        ...filter,
        page: page + 1,
        size,
        order,
        order_by: orderBy
      })
        .then((res) => {
          setData(res.data.contents);
          setTotalData(res.data.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const exportVisitation = () => {
    ApiClient.Download(ApiPath.DOWNLOAD_VISITATION, [], filter).then((res) => {
      const link = document.createElement('a');
      link.href = res.data;
      const blob = new Blob([res.data], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `${moment(filter.start_date).format('YYYY-MM-DD')}_${moment(
        filter.end_date
      ).format('YYYY-MM-DD')}_visitation.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <TableContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        data,
        filter,
        page,
        order,
        orderBy,
        loading,
        setData,
        setFilter,
        setPage,
        setOrderBy,
        setOrder,
        setLoading,
        checked,
        setChecked,
        handleChangePage,
        handleChangeRowsPerPage,
        totalData,
        size,
        setSize,
        getData,
        setGetUrl,
        exportVisitation
      }}
    >
      {children}
    </TableContext.Provider>
  );
}

export { TableContextProvider, TableContext };
