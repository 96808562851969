import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';

import { CustomerRepository } from '../repositories/CustomerRepository';
import { stringToNumber } from '../utils/formatNumber';

function CustomerDetail() {
  const { id } = useParams();

  useEffect(() => {
    if (id !== null) {
      CustomerRepository.show(id).then((res) => {
        setData(res.data.content);
      });
    }
  }, [id]);

  const [data, setData] = useState({});

  const renderField = (label, value) => (
    <TableRow>
      <TableCell style={{ fontWeight: 'bold' }}>{label}</TableCell>
      <TableCell>{value === undefined || value === null ? '-' : value}</TableCell>
    </TableRow>
  );

  return (
    <Page title="Customers">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h3">Customer Detail</Typography>
              <Table>
                <TableBody>
                  {renderField('Name', data.name)}
                  {renderField('Sales', data.sales)}
                  {renderField('Category', data.category)}
                  {renderField('Status', data.status)}
                  {renderField('Phone Number', data.phone_number)}
                  {renderField('Company Name', data.company_name)}
                  {renderField('Address', data.address)}
                  {renderField('Instagram', data.instagram)}
                  {renderField('Email', data.email)}
                  {renderField('Web', data.web)}
                  {renderField('Classification', data.classification)}
                  {renderField('Category', data.category)}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3">Own Brands</Typography>
                <Table>
                  <TableBody>
                    {data.own_brands !== undefined &&
                      data.own_brands.map((res) => (
                        <TableRow>
                          <TableCell>{res}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3">Other Brands</Typography>
                <Table>
                  <TableBody>
                    {data.other_brands !== undefined &&
                      data.other_brands.map((res) => (
                        <TableRow>
                          <TableCell>{res}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3">Pics</Typography>
                <Table>
                  <TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Position</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Birthdate</TableCell>
                      </TableRow>
                    </TableHead>
                    {data.pics !== undefined &&
                      data.pics.map((res) => (
                        <TableRow>
                          <TableCell>{res.name === null ? '-' : res.name}</TableCell>
                          <TableCell>{res.position === null ? '-' : res.position}</TableCell>
                          <TableCell>{res.email === null ? '-' : res.email}</TableCell>
                          <TableCell>{res.phone === null ? '-' : res.phone}</TableCell>
                          <TableCell>{res.birthdate === null ? '-' : res.birthdate}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3">Product Lines</Typography>
                <Table>
                  <TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Type</TableCell>
                        <TableCell>Product Line</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    {data.product_lines !== undefined &&
                      data.product_lines.map((res) => (
                        <TableRow>
                          <TableCell>
                            {res.product_type === null ? '-' : res.product_type}
                          </TableCell>
                          <TableCell>
                            {res.product_line === null ? '-' : res.product_line}
                          </TableCell>
                          <TableCell>
                            {res.quantity === null ? '-' : stringToNumber(res.quantity.toString())}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h3">Visitations (Last 30 Days)</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Clock In</TableCell>
                    <TableCell>Clock Out</TableCell>
                    <TableCell>Trip Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.visitations !== undefined &&
                    data.visitations.map((res) => (
                      <TableRow
                        onClick={() => {
                          window.location.href = `/visitations/${res.id}`;
                        }}
                      >
                        <TableCell>{res.day}</TableCell>
                        <TableCell>{res.date}</TableCell>
                        <TableCell>{res.clock_in}</TableCell>
                        <TableCell>{res.clock_out}</TableCell>
                        <TableCell>{res.trip_time}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default CustomerDetail;
