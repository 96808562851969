export const generateFilterField = (name, label, type, options, async, defaultValue) => ({
  name,
  label,
  type,
  options,
  async,
  defaultValue
});

export const generateAsyncProps = (url, valueKey, textKey) => ({
  url,
  valueKey,
  textKey
});

export const generateOptionProps = (text, value) => ({ text, value });

export const generateInputFieldProps = (
  name,
  label,
  type,
  options,
  async,
  defaultValue,
  defaultValueKey,
  disabled,
  hidden
) => ({
  name,
  label,
  type,
  options,
  async,
  defaultValue,
  defaultValueKey,
  disabled,
  hidden
});

export const generateHeadCell = (name, label, type, sortable) => ({
  name,
  label,
  type,
  sortable
});
