import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Page from '../components/Page';
import { VisitationRepository } from '../repositories/VisitationRepository';

function VisitationDetail() {
  const { id } = useParams();

  useEffect(() => {
    if (id !== null) {
      VisitationRepository.show(id).then((res) => {
        setData(res.data.content);
      });
    }
  }, [id]);

  const [data, setData] = useState({});

  const renderField = (label, value) => (
    <TableRow>
      <TableCell style={{ fontWeight: 'bold' }}>{label}</TableCell>
      <TableCell>{value === null || value === undefined ? '-' : value}</TableCell>
    </TableRow>
  );

  return (
    <Page title="Visitation Detail">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h3">Visitation Detail</Typography>
              <Table>
                <TableBody>
                  {renderField('Day', data.day)}
                  {renderField('Date', data.date)}
                  {renderField('Sales', data.sales)}
                  {renderField('Customer', data.customer)}
                  {renderField('Clock In', data.clock_in)}
                  {renderField('Clock Out', data.clock_out)}
                  {renderField('Trip Time', data.trip_time)}
                  {renderField('Information', data.information)}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3">Activities</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Activity</TableCell>
                      <TableCell>Product</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.activities !== undefined &&
                      data.activities.map((p) => (
                        <TableRow>
                          <TableCell>{p.activity}</TableCell>
                          <TableCell>{p.product === null ? '-' : p.product}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3">Follow Up Progress</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Activity</TableCell>
                      <TableCell>Product</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.follow_ups !== undefined &&
                      data.follow_ups.map((p) => (
                        <TableRow>
                          <TableCell>{p.activity}</TableCell>
                          <TableCell>{p.product === null ? '-' : p.product}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            {data.longitude !== null &&
              data.latitude !== null &&
              data.longitude !== undefined &&
              data.latitude !== undefined && (
                <Grid item xs={12} md={12}>
                  <Paper sx={{ padding: 2 }}>
                    <Typography variant="h3">Location</Typography>
                    <LoadScript googleMapsApiKey="AIzaSyBx7ShUVzDevI87Z1YzWhAsKtp1liu1GBY">
                      <GoogleMap
                        mapContainerStyle={{
                          width: '100%',
                          height: '500px'
                        }}
                        center={{
                          lat: data.latitude,
                          lng: data.longitude
                        }}
                        zoom={15}
                      >
                        <Marker
                          position={{
                            lat: data.latitude,
                            lng: data.longitude
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  </Paper>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default VisitationDetail;
