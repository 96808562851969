import ApiClient from '../utils/api/ApiClient';
import { ApiPath } from '../constants/ApiPath';

const axios = require('axios');
const cookies = require('react-cookies');

export class UserRepository {
  static login = (body) => ApiClient.Post(ApiPath.LOGIN, [], body);

  static logout = () =>
    axios.get(ApiPath.LOGOUT, {
      headers: {
        Authorization: `Bearer ${cookies.load('ACCESS_TOKEN')}`
      }
    });

  static getUserList = (filter, pagination) =>
    ApiClient.Post(ApiPath.USER_LIST, [], {
      filter,
      pagination
    });

  static getCurrentUser = () => ApiClient.Get(ApiPath.CURRENT_USER, [], {});
}
