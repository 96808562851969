import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select as MaterialSelect } from '@mui/material';
import ApiClient from '../utils/api/ApiClient';

function Select(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.async !== undefined && props.async !== null) {
      ApiClient.Get(props.async.url, [], {}).then((res) => {
        setOptions(
          res.data.contents.map((content) => ({
            value: content[props.async.valueKey],
            text: content[props.async.textKey]
          }))
        );
      });
    }
  }, []);

  return (
    <FormControl
      fullWidth
      sx={{
        width:
          props.width !== undefined
            ? props.width
            : {
                md: '250px !important',
                xs: '100%'
              }
      }}
    >
      <InputLabel>{props.label}</InputLabel>
      <MaterialSelect
        value={props.value}
        onChange={(e) => {
          props.onChange(props.name, e.target.value.toString());
        }}
      >
        <MenuItem value="">
          <em>Pilih {props.label}</em>
        </MenuItem>
        {props.async !== undefined &&
          options.length > 0 &&
          options.map((option) => (
            <MenuItem value={option.value.toString()}>{option.text}</MenuItem>
          ))}
        {props.options !== undefined &&
          props.options !== null &&
          props.options.map((option) => (
            <MenuItem value={option.value.toString()}>{option.text}</MenuItem>
          ))}
      </MaterialSelect>
    </FormControl>
  );
}

export default Select;
