import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Product from './pages/Product';
import NotFound from './pages/Page404';
import Customer from './pages/Customer';
import Visitation from './pages/Visitation';
import FollowUp from './pages/FollowUp';
import FollowUpDetail from './pages/FollowUpDetail';
import CustomerDetail from './pages/CustomerDetail';
import VisitationDetail from './pages/VisitationDetail';
import Survey from './pages/Survey';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/products" /> },
        { path: 'customers', element: <Customer /> },
        { path: 'customers/:id', element: <CustomerDetail /> },
        { path: 'products', element: <Product /> },
        { path: 'visitations', element: <Visitation /> },
        { path: 'visitations/:id', element: <VisitationDetail /> },
        { path: 'follow-ups', element: <FollowUp /> },
        { path: 'follow-ups/:id', element: <FollowUpDetail /> },
        { path: 'surveys', element: <Survey /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/products" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
