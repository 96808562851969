import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { TableContext } from '../../context/TableContext';
import MySwal from '../../utils/swal';
import ApiClient from '../../utils/api/ApiClient';
import Input from '../Input';
import DefaultProductInput from './DefaultProductInput';
import { CategoryRepository } from '../../repositories/CategoryRepository';
import { ProductLinesRepository } from '../../repositories/ProductLinesRepository';

DefaultCreateDialog.propTypes = {
  fields: PropTypes.array,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  createUrl: PropTypes.string,
  hasProducts: PropTypes.bool,
  products: PropTypes.array
};

function DefaultCreateDialog({ open, title, fields, onClose, createUrl, hasProducts, products }) {
  const context = useContext(TableContext);

  const [request, setRequest] = useState({});
  const [categories, setCategories] = useState([]);
  const [productLines, setProductLines] = useState([]);

  useEffect(() => {
    const temp = {};
    if (open === true) {
      fields.forEach((field) => {
        temp[field.name] = field.defaultValue;
      });
      if (hasProducts) {
        temp.items = [];
      }
      if (title === 'Survey') {
        temp.product_lines = [];
        temp.categories = [];
        CategoryRepository.get().then((res) => {
          setCategories(res.data.contents);
        });
        ProductLinesRepository.get().then((res) => {
          setProductLines(res.data.contents);
        });
      }
      setRequest(temp);
    } else {
      const temp = {};
      if (hasProducts) {
        temp.items = [];
      }
      if (title === 'Survey') {
        temp.categories = [];
        temp.product_lines = [];
      }
      setRequest(temp);
    }
  }, [open]);

  const handleSubmit = () => {
    MySwal.yesNo(`Tambah ${title}`, 'Apakah anda ingin menambahkan data?').then((res) => {
      if (res.isConfirmed) {
        ApiClient.Post(createUrl, [], request)
          .then((res) => {
            MySwal.success('Sukses', `Sukses menambahkan ${title}`).then((res) => {
              onClose();
            });
            context.getData();
          })
          .finally(() => {
            context.setLoading(false);
          });
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tambah {title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ marginTop: '10px' }}>
          {fields.map((field) => (
            <Grid item xs={12}>
              <Input
                {...field}
                width="100%"
                value={request[field.name]}
                onChange={(name, value) => {
                  setRequest({
                    ...request,
                    [name]: value
                  });
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            {hasProducts === true && (
              <DefaultProductInput
                products={products}
                setValues={(values) => {
                  setRequest({
                    ...request,
                    items: values
                  });
                }}
                values={request.items}
              />
            )}
          </Grid>
        </Grid>
        {title === 'Survey' && (
          <Grid item xs={12}>
            <Typography variant="h6">Categories</Typography>
            <Grid container spacing={1}>
              {categories.map((category) => (
                <Grid item>
                  <Chip
                    label={category.name}
                    color={request.categories.includes(category.id) ? 'success' : 'default'}
                    onClick={() => {
                      const temp = { ...request };
                      if (temp.categories.includes(category.id)) {
                        temp.categories = temp.categories.filter((c) => c !== category.id);
                      } else {
                        temp.categories.push(category.id);
                      }
                      console.log(temp.categories);
                      setRequest(temp);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {title === 'Survey' && (
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <Typography variant="h6">Product Lines</Typography>
            <Grid container spacing={1}>
              {productLines.map((productLine) => (
                <Grid item>
                  <Chip
                    label={productLine.name}
                    color={request.product_lines.includes(productLine.id) ? 'success' : 'default'}
                    onClick={() => {
                      const temp = { ...request };
                      if (temp.product_lines.includes(productLine.id)) {
                        temp.product_lines = temp.product_lines.filter((c) => c !== productLine.id);
                      } else {
                        temp.product_lines.push(productLine.id);
                      }
                      console.log(temp.product_lines);
                      setRequest(temp);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Batal</Button>
        <Button onClick={handleSubmit}>Tambah</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DefaultCreateDialog;
