import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, IconButton, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Select from '../select';
import Input from '../Input';
import { InputType } from '../../constants/InputType';

DefaultProductInput.propTypes = {
  products: PropTypes.array,
  values: PropTypes.any,
  setValues: PropTypes.func
};

function DefaultProductInput({ values, products, setValues }) {
  const onChange = (idx, key, value) => {
    setValues(
      values.map((val, i) => {
        if (i === idx) {
          val[key] = value;
        }
        return val;
      })
    );
  };

  const onDelete = (idx) => {
    const temp = values;
    temp.splice(idx, 1);
    setValues(temp);
  };

  const onAdd = () => {
    const temp = {
      quantity: 0,
      product_id: '',
      price: 0
    };
    const tempArray = values;
    tempArray.push(temp);
    setValues(tempArray);
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h5">Produk</Typography>
        <IconButton
          sx={{ color: 'text.primary' }}
          onClick={() => {
            onAdd();
          }}
        >
          <AddIcon />
        </IconButton>
      </Grid>
      {values.map((val, idx) => (
        <Grid
          item
          container
          xs={12}
          spacing={1}
          sx={{
            alignItems: 'center'
          }}
        >
          <Grid item xs={6}>
            <Select
              label="Produk"
              name="product_id"
              options={products}
              async={null}
              onChange={(key, value) => {
                onChange(idx, 'product_id', value);
              }}
              value={val.product_id}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              label="Jumlah"
              value={val.quantity}
              type={InputType.NUMBER}
              onChange={(key, value) => {
                onChange(idx, 'quantity', value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Harga"
              value={val.price}
              type={InputType.NUMBER}
              onChange={(key, value) => {
                onChange(idx, 'price', value);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={{ color: 'text.primary' }}
              onClick={() => {
                onDelete(idx);
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default DefaultProductInput;
