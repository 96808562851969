import { Constant } from './Constant';

export const ApiPath = {
  LOGIN: `${Constant.API_URL}/admin/auth/login`,
  LOGOUT: `${Constant.API_URL}/auth/logout`,
  CURRENT_USER: `${Constant.API_URL}/auth/user`,

  GET_CUSTOMERS_LIST: `${Constant.API_URL}/admin/customers`,
  GET_CUSTOMER_DETAIL: `${Constant.API_URL}/admin/customers/{}`,

  GET_PRODUCT_LIST: `${Constant.API_URL}/admin/products`,
  GET_VISITATION_LIST: `${Constant.API_URL}/admin/visitations`,
  DOWNLOAD_VISITATION: `${Constant.API_URL}/admin/visitations/download`,
  GET_VISITATION_DETAIL: `${Constant.API_URL}/admin/visitations/{}`,
  GET_FOLLOW_UP_LIST: `${Constant.API_URL}/admin/follow-ups`,
  GET_FOLLOW_UP_DETAIL: `${Constant.API_URL}/admin/follow-ups/{}`,
  GET_CATEGORIES: `${Constant.API_URL}/categories`,
  GET_CLASSIFICATIONS: `${Constant.API_URL}/classifications`,
  GET_SURVEY_LIST: `${Constant.API_URL}/admin/surveys`,
  CREATE_SURVEY: `${Constant.API_URL}/admin/surveys`,
  UPDATE_SURVEY: `${Constant.API_URL}/admin/surveys/{}`,
  DELETE_SURVEY: `${Constant.API_URL}/admin/surveys/{}`,
  GET_PRODUCT_LINE_LIST: `${Constant.API_URL}/product-lines`
};
